import React from 'react'

const Footer = () => {
    return (
        <div className='footer-component mt-5'>
            <p style={{"color": "gray"}}>Icons by <a href="https://icons8.com/" style={{"color": "gray"}}>Icons8</a></p>
        </div>
    )
}

export default Footer